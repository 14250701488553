import React from 'react';
import Navbar from './components/Header/Navbar';
import Hero from './components/Header/Hero';
import Programs from './components/Programs/programs';
import './App.css';
import Reasons from './components/Reasons/Reasons';
import Plans from './components/Plans/Plans';
import Testimonial from './components/Testimonial/Testimonial';
import Join from './components/Join/Join';
import Footer from './components/Footer/Footer';


function App() {
  return (
    <div className="App">
        {/* <Navbar /> */}
        <Hero />
        <Programs />
        <Reasons/>
        <Plans/>
        <Testimonial/>
        <Join />
        <Footer/>

    </div>
  );
}

export default App;
