import React, { useState ,useRef} from "react";
import './Join.css';
import {testimonialsData} from '../../data/testimonialsData';
import leftArrow from '../../assets/leftArrow.png';
import rightArrow from '../../assets/rightArrow.png';
import emailjs from '@emailjs/browser'


const Join=()=>{
    
    const form=useRef()
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
          .sendForm('service_wdbgrbd', 'template_floith3', form.current, {
            publicKey: 'AlacoNgDVJfxKDquQ',
          })
          .then(
            () => {
              console.log('SUCCESS!');
            },
            (error) => {
              console.log('FAILED...', error.text);
            },
          );
      };
    return(
        <div className="Join" id="join-us">
            <div className="left-j">
                <hr/>
                <div>
                    <span className="stroke-text">Ready to </span>
                    <span>Level Up</span>
                </div>
                <div>
                    <span> Your Body</span>
                    <span className="stroke-text"> With Us</span>
                </div>
            </div>
            <div className="right-j">
                <form ref={form} className="email-container" onSubmit={sendEmail}>
                    <input type="text" name="user-email" placeholder="Enter your Email"/>
                    <button className="btn btn-j">Join Us</button>
                </form>

            </div>

        </div>    
    )
}

export default Join;