import React, { useState ,useRef} from "react";
import './Footer.css';
import {testimonialsData} from '../../data/testimonialsData';
import Github from '../../assets/github.png';
import instagram from '../../assets/instagram.png';
import LinkedIn from '../../assets/linkedin.png';
import Logo from '../../assets/logo.png';

const Footer=()=>{
    
return(
       <div className="Footer-container">
        <hr/>
        <div className="Footer">
            <div className="social-links">
            <img src={Github} alt=""/>
            <img src={instagram} alt=""/>
            <img src={LinkedIn} alt=""/>
            </div>


        <div className="logo-f">
            <img src={Logo} alt=""/>
        </div>
        </div>  
        <div className="blur blur-f-1"></div>
        <div className="blur blur-f-2"></div>
        </div>  
    )
}

export default Footer;