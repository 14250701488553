import React, { useState } from 'react';
import './Navbar.css';
import Logo from '../../assets/logo.png';
import Bars from '../../assets/bars.png';
import { Link } from 'react-scroll';
const Navbar = () => {
  const mobile=window.innerWidth<=768 ? true:false;
const [menuOpened,setmenueOpened]=useState(false);
  return(
  <div className='Navbar'>
    <img src={Logo} alt='LOGO' className='logo'/>
    {(menuOpened===false && mobile===true)?
    (<div style={{backgroundColor:"var(--appColor)",padding:"0.5rem", borderRadius:"5px",

    }}
    onClick={()=>setmenueOpened(true)}><img src={Bars} alt='' style={{width:'1.5rem',height:'1.5rem'}} /></div>):(
    <ul className='nav-menu'>
      <li onClick={()=>setmenueOpened(false)}>
      <Link activeClass="active" to="header" spy={true} smooth={true}>Home</Link></li>
        
      <li onClick={()=>setmenueOpened(false)}>
      <Link activeClass="active" to="programs" spy={true} smooth={true}>Programs</Link></li>
      
      <li onClick={()=>setmenueOpened(false)}>
      <Link activeClass="active" to="plans" spy={true} smooth={true}>Plans</Link></li>
        <li onClick={()=>setmenueOpened(false)}>
          <Link to='Testimonial' span={true} smooth={true}>Testimonials</Link></li>
        
    </ul>
    )}
  </div>
  );
};

export default Navbar;
