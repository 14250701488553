import React from 'react';
import Navbar from './Navbar';
import hero_image from '../../assets/hero_image.png';
import hero_image_back from '../../assets/hero_image_back.png';
import Heart from '../../assets/heart.png';
import Calories from '../../assets/calories.png';
import './Hero.css';
import { motion } from 'framer-motion';
import NumberCounter from 'number-counter';

const Hero = () => {

  const transition={type:'spring' ,duration:3}
  const mobile=window.innerWidth<=768 ? true:false;
  return (
    <div className="Hero" id='home'>
      <div className='blur hero-blur'></div>
      <div className='left-side'>
        <Navbar />
        <div className='the-best-ad'>
          <motion.div
            initial={{left: mobile? "178px":'238px'}}
            whileInView={{left:'8px'}}
            transition={{...transition, type:'tween'}}>
          </motion.div>
          <span>the best fitness ad in town</span>
        </div>


        <div className='Hero-text'>
          <div>
            <span className='stroke-text'>Shape</span>
            <span> Your</span>
          </div>

          <div>
            <span>Ideal Body</span>
          </div>

          <div>
            <span>I am here to help you live your life to the fullest</span>
          </div>
        </div>

        <div className='figures'>
          <div><span><NumberCounter end={140} start={100} delay="4" preFix="+"/> </span>
            <span>Expert Coaches</span></div>
          <div><span><NumberCounter end={978} start={800} delay="4" preFix="+"/></span><span>Members Joined</span></div>
          <div><span><NumberCounter end={50} start={0} delay="4" preFix="+"/></span><span>Fitness Programs</span></div>
        </div>

        <div className='Hero-btns'>
          <button className='btn'>Get Started</button>
          <button className='btn'>Learn More</button>
        </div>

      </div>
      <div className='right-side'>
        <button className='btn'>Join Now</button>
      
        <motion.div transition={transition}  initial={{rigjt:'-1rem'}}
            WhileInView={{right:'4rem'}}
            className='heart-rate'>
          <img src={Heart} alt="" />
          <span>heart rate</span>
          <span>116 bpm</span>
        </motion.div>

        <img src={hero_image} alt='hero image' className='hero-image' />
        <motion.img             initial={{right:'11rem'}}
            whileInView={{right:'20rem'}}
            transition={{...transition,type:'tween'}}  src={hero_image_back} alt='hero image back' className='hero-image-back' />



      </div>


      <motion.div  initial={{right:'37rem'}}
            whileInView={{right:'28rem'}}
            transition={{...transition,type:'tween'}} className='calories'>
        <img src={Calories} alt='' />

        <div>
          <span>Calories Burned</span>
          <span>220 KCal</span>
        </div>
      </motion.div>

    </div>
  );
}

export default Hero;
